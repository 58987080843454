import React from 'react';

import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Slider from '../components/slider';
import dataSlider from '../assets/fake-data/data-slider';
import Project from '../features/project';
import dataProject from '../assets/fake-data/dataProject';
import Work from '../features/work';
import dataWork from '../assets/fake-data/data-work';
import Team from '../features/team';
import Copy from '../features/copy';
import Benefits from '../features/benefits';
import Airdrop from '../features/airdrop';
import dataTeam from '../assets/fake-data/data-team';
import FAQ from '../features/faq';
import dataFaq from '../assets/fake-data/data-faq';
import { Helmet } from 'react-helmet';


function HomeOne(props) {

    return (
        
        <div className='home-1'>
        <Helmet>
            <title>Dobutsu NFT</title>
        </Helmet>
        <Slider data={dataSlider} />

        <Work data={dataWork} />

        <Project data={dataProject} />

        <Team data={dataTeam} />

        <Copy data={dataTeam} />

        <Benefits data={dataTeam} />

        <Airdrop data={dataTeam} />

        <FAQ data={dataFaq} />
        
        </div>
    );
}

export default HomeOne;