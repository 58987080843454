import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Scrollbar, A11y, Navigation } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import CopyTrading from "../../assets/images/common/image03.png"
import { Link } from 'react-router-dom';


Team.propTypes = {
  data: PropTypes.array,
};

const swiperOptions = {
  modules: [Navigation, Scrollbar, A11y],
  spaceBetween: 30,
slidesPerView: 1,

  navigation: {
    clickable: true,
    nextEl: ".nav-prev-testimonial",
    prevEl: ".nav-next-testimonial",
  },
  breakpoints: {
    575: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 3,
    },
  },
  // navigation
  scrollbar: { draggable: true },
};

function Team(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "Heading",
    title: "Whale Benefits",
    desc: "Who we are",
    text: "Unlock Exclusive Privileges and Premium Perks with Dobutsus! Experience the Ultimate in Luxury and Advantage with Special Benefits Reserved for Our Most Distinguished Collectors. Enjoy Priority Access, Unique Rewards, and Tailored Experiences Designed to Enhance Your NFT Journey.",
  });
  return (
    <section className="tf-section">
      <div className="container-sm text-center">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-5 col-md-6 col-12">
            <div
              className="tf-title st2 mb-61 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{dataBlock.subtitle}</p>
              <h4 className="title">{dataBlock.title}</h4>
            </div>
            <div
              className="content mobie-30"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p>{dataBlock.text}</p>
            </div>
          </div>
          <div className="col-xl-12 col-lg-7 col-md-6 col-12">
            <img className="w-50" src={CopyTrading} alt="" />
          </div>
          <div className="box-navigation justify-content-center">
            <Link to="https://opensea.io/collection/dobutsunfts/overview" className="tf-button-st2 btn-effect" data-toggle="modal" data-target="#popup_bid2"><span className="effect mt-2">Get nfts</span></Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
