const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'NFTs (Non-Fungible Tokens) are unique digital assets that represent ownership or proof of authenticity of a specific item, usually on the blockchain. Unlike cryptocurrencies like Bitcoin or Ethereum, NFTs are indivisible and unique, making them perfect for digital art, collectibles, and other unique digital items.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do i get NFTs?',
        content: "To acquire NFTs, you'll need a digital wallet compatible with NFTs, such as MetaMask. You can then visit NFT marketplaces like OpenSea, Rarible, or our own Dobutsus marketplace to browse, purchase, and trade NFTs. Simply connect your wallet, browse the available collections, and complete the purchase using cryptocurrency.",
    },
    {
        id: 3,
        title: 'How can we buy your NFTs?',
        content: 'To buy Dobutsus NFTs, visit our official marketplace or partner platforms. Ensure your digital wallet is funded with the required cryptocurrency, connect your wallet to the marketplace, and browse our unique collection of Dobutsu NFTs. Once you find an NFT you like, follow the purchase process to add it to your collection.',
    },
    {
        id: 4,
        title: 'How can I see the Discord bot?',
        content: "To check out our Discord bot, join our official Dobutsus Discord server. Once you're a member, you can interact with the bot to get updates, participate in community activities, and access exclusive features. The bot is designed to enhance your experience and keep you connected with the Dobutsus community.",
    },
]

export default dataFaq;