const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Social',
        text : 'Connect with a vibrant community of Dobutsu collectors and enthusiasts, sharing tips, stories, and experiences to enrich your journey.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Expert Analysis',
        text : 'Gain insights from top analysts in the NFT space. Stay ahead with expert advice and strategies tailored to maximize the value of your Dobutsu.'
    },
    {
        id: 3,
        numb: '03',
        title: "Unique Design",
        text : 'Each Dobutsu NFT features stunning and unique artwork, crafted by talented artists. Discover the beauty and creativity behind every piece.'
    },
    {
        id: 4,
        numb: '04',
        title: "Automated Copytrading",
        text : 'Leverage advanced automated tools for seamless and efficient trading experiences. Mirror the strategies of successful traders effortlessly.'
    },
    {
        id: 5,
        numb: '05',
        title: "Airdrop Fund",
        text : 'Benefit from exclusive airdrops and additional fund rewards, giving you access to new and exciting opportunities within the Dobutsu.'
    },
    {
        id: 6,
        numb: '06',
        title: "Extra utility",
        text : 'Unlock a range of extra utilities designed to enhance your Dobutsu experience. From special features to unique perks.'
    },
]
export default dataWork;