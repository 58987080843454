const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/'
    },
    {
        id: 2,
        name: 'Instant utility',
        links: '#instant'
    },
    {
        id: 3,
        name: 'FAQS',
        links: '#faqs'
    },
    {
        id: 4,
        name: 'Contact',
        links: '#footer'
    },
    
]

export default menus;