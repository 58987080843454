import bgImg1 from '../images/background/bg-1.png';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/itemslider.png';

const dataSlider = [
    {
        id: 1,
        title: 'Welcome to the jungle',
        desc : 'Discover the Magical World of Dobutsus! Collect, Trade, and Explore Unique Digital Creatures. Join the Adventure Today!',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 2,
        title: 'Welcome to the jungle',
        desc : 'Discover the Magical World of Dobutsus! Collect, Trade, and Explore Unique Digital Creatures. Join the Adventure Today!',
        bgImg: bgImg2,
        classAction: 'two'
    },
    {
        id: 3,
        title: 'Welcome to the jungle',
        desc : 'Discover the Magical World of Dobutsus! Collect, Trade, and Explore Unique Digital Creatures. Join the Adventure Today!',
        bgImg: bgImg3,
        classAction: 'three'
    },
    {
        id: 4,
        title: 'Welcome to the jungle',
        desc : 'Discover the Magical World of Dobutsus! Collect, Trade, and Explore Unique Digital Creatures. Join the Adventure Today!',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 5,
        title: 'Welcome to the jungle',
        desc : 'Discover the Magical World of Dobutsus! Collect, Trade, and Explore Unique Digital Creatures. Join the Adventure Today!',
        bgImg: bgImg2,
        classAction: 'two'
    },

]

export default dataSlider;